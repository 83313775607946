<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />
        <div v-if="!loading">
          <crud-list
            v-model="fazendas"
            :headers="headers"
            :slots="['item.telefones']"
            :actions="this.$store.state.user.roles[0].slug !== 'tecnico' ? actions : []"
            :show-expand="true"
            :permission="'list_fazenda'"
            :sort-by="['razao_social']"
            :sort-desc="[false]"
            export-pdf-xlsx
          >
            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length">
                <v-card-title>
                  <span style="font-size: 14px; color: rgba(0, 0, 0, 0.87); font-weight: bold"> Produtores Vinculados</span>
                </v-card-title>
                <v-list-item
                  v-for="produtor, index in item.produtores"
                  :key="produtor.nome"
                  style="min-height: 20px; max-height: 52px;  padding: 0px;  padding-top: 0px;"
                >
                  <v-list-item-content>
                    <v-card
                      elevation="4"
                      outlined
                      class="my-n1"
                    >
                      <v-row>
                        <v-card-actions class="ml-6 my-0 py-1">
                          <v-col class="my-0 py-0">
                            <v-row class="my-0 py-0">
                              <v-btn
                                v-if="hasPermission('ativar_fazenda')"
                                icon
                                small
                                title="Remover Produtor da Fazenda"
                                @click="
                                  DELETE_CAD_FAZENDA({
                                    id: produtor.cadastro.id,
                                    produtorId: produtor.id,
                                    fazendaId: item.id,}
                                  )
                                "
                              >
                                <v-icon color="red darken-3">
                                  mdi-trash-can-outline
                                </v-icon>
                              </v-btn>
                              <div v-if="hasPermission('ativar_fazenda')">
                                <v-btn
                                  v-if="!produtor.cadastro.ativo"
                                  icon
                                  small
                                  title="Ativar Produtor da Fazenda"
                                  @click="ATIVAR_CAD_FAZENDA({
                                    id: produtor.cadastro.id,
                                    produtorId: produtor.id,
                                    fazendaId: item.id,}
                                  )"
                                >
                                  <v-icon
                                    color="orange lighten-1"
                                  >
                                    mdi-check-circle-outline
                                  </v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="produtor.cadastro.ativo"
                                  icon
                                  small
                                  title="Inativar Produtor da Fazenda"
                                  @click="INATIVAR_CAD_FAZENDA({
                                    id: produtor.cadastro.id,
                                    produtorId: produtor.id,
                                    fazendaId: item.id,
                                  })"
                                >
                                  <v-icon
                                    color="red lighten-1"
                                  >
                                    mdi-close-circle-outline
                                  </v-icon>
                                </v-btn>
                              </div>
                              <v-chip
                                v-if="!produtor.cadastro.ativo"
                                color="red lighten-1"
                                class="mb-2 pl-4 pr-4 my-1"
                                x-small
                                text-color="white"
                              >
                                <b>Inativo</b>
                              </v-chip>
                              <v-chip
                                v-if="produtor.cadastro.ativo"
                                color="green lighten-2"
                                class="mb-2 pl-4 pr-4 my-1"
                                x-small
                                text-color="white"
                              >
                                <b>Ativo</b>
                              </v-chip>
                            </v-row>
                          </v-col>
                        </v-card-actions>
                        <v-card
                          elevation="0"
                          :disabled="!produtor.cadastro.ativo"
                          class="my-3 py-0"
                        >
                          <v-card-text class="mb-2 pl-2 pr-2 my-2 py-1">
                            <div>
                              <span style="font-size: 12px; color: rgba(0, 0, 0, 0.87); font-weight: bold">{{ produtor.cpf ? `${produtor.nome} (${produtor.cpf})` : `${produtor.razao_social} (${produtor.cnpj})` }}</span>
                              <span
                                class="ml-3"
                                style="font-size: 12px; color: rgba(0, 0, 0, 0.87); font-weight: bold"
                              >
                                Código Fornecedor: </span><span style="font-size: 12px; color: rgba(0, 0, 0, 0.87)"> {{ produtor.cadastro.cod_produtor_fornecedor }}</span>
                              <span
                                class="ml-3"
                                style="font-size: 12px; color: rgba(0, 0, 0, 0.87); font-weight: bold"
                              >Código Cliente: </span><span style="font-size: 12px; color: rgba(0, 0, 0, 0.87)"> {{ produtor.cadastro.cod_produtor_cliente }}</span>
                              <span
                                class="ml-3"
                                style="font-size: 12px; color: rgba(0, 0, 0, 0.87); font-weight: bold"
                              >
                                Técnico:
                              </span>
                              <span
                                v-if="item.tecnicos[index]?.pessoafisica"
                                style="font-size: 12px; color: rgba(0, 0, 0, 0.87)"
                              >
                                {{ item.tecnicos[index]?.pessoafisica ? `${item.tecnicos[index]?.pessoafisica.nome} (${item.tecnicos[index].pessoafisica.cpf})` : '' }}
                              </span>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-row>
                    </v-card>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-show="cadForm.fazenda_id === item.id">
                  <v-list-item-content>
                    <cad-produtor-fazenda
                      v-model="cadForm"
                      @produtor-fazenda="ADD_CARD_CADASTRO"
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="$store.getters.hasPermission('ativar_produtor')"
                  style="padding-left: 0;"
                >
                  <v-list-item-content>
                    <div>
                      <v-btn
                        class="text-none text-white"
                        dark
                        small
                        color="success"
                        elevation="3"
                        rounded
                        title="Inclui um novo Produtor."
                        @click="mostraForm(item.id)"
                      >
                        <v-icon
                          small
                          dark
                        >
                          mdi-plus
                        </v-icon>
                        Incluir um novo Produtor
                      </v-btn>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </td>
            </template>
            <template
              v-if="hasPermission('inserir_fazenda') && $store.state.user.roles[0].slug !== 'tecnico'"
              v-slot:btnCadastro
            >
              <v-col>
                <v-btn
                  class="text-none text-white"
                  block
                  color="primary"
                  title="Cadastrar nova fazenda"
                  rounded
                  @click="$router.push({ path: 'fazendas/cadastrar' })"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Cadastrar
                </v-btn>
              </v-col>
            </template>
            <template v-slot:[`item.telefones`]="{ item }">
              <div
                v-for="(telefone, index) in item.telefones"
                :key="index"
              >
                <span v-if="telefone">
                  {{ `${index + 1}:  ${telefone}` }}
                  <br>
                </span>
              </div>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import CadProdutorFazenda from './CadProdutorFazenda'
  import store from '@/store'
  import FazendasStore, { BOOTSTRAP, ATIVAR_CAD_FAZENDA, DELETE_CAD_FAZENDA, INATIVAR_CAD_FAZENDA, ADD_CARD_CADASTRO, INATIVAR_FAZENDA } from '@/store/modules/fazendas'
  import { mapState, mapActions, mapGetters } from 'vuex'
  export default {
    components: {
      CadProdutorFazenda,
    },
    data () {
      return {
        actions: [
          {
            title: 'Editar Fazenda',
            color: 'info darken-3',
            click: item => this.editarFazenda(item),
            icon: 'mdi-pencil',
            permission: 'editar_fazenda',
          },
          {
            title: 'Excluir Fazenda',
            color: 'red darken-3',
            click: item => this.INATIVAR_FAZENDA(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_fazenda',
          },
        ].filter(({ permission }) => store.getters.hasPermission(permission)),
        cadForm: {
          produtor_id: null,
          cod_produtor_fornecedor: null,
          cod_produtor_cliente: null,
          fazenda_id: null,
        },
        headers: [
          { text: '', value: 'data-table-expand', sortable: false, groupable: false },
          { text: '', value: 'actions', sortable: false, groupable: false, width: '40px' },
          { align: 'left', sortable: true, groupable: false, text: 'CNPJ', value: 'cnpj', width: 'auto', floatingfilter: true },
          { align: 'left', sortable: true, groupable: false, text: 'Razão Social', value: 'razao_social', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'Nome Fantasia', value: 'nome_fantasia', width: 'auto', floatingfilter: true },
          { align: 'left', sortable: false, groupable: false, text: 'Telefones', value: 'telefones', width: 'auto', styleXLSX: { type: 'telefone' } },
          { align: 'left', sortable: true, groupable: true, text: 'Unidade de Negócio', value: 'unidade_neg.nome_fantasia', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'Inscrição Estadual', value: 'inscricao_estadual', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Logradouro', value: 'endereco.logradouro', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Número', value: 'endereco.numero', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Complemento', value: 'endereco.complemento', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Bairro', value: 'endereco.bairro', width: 'auto', floatingfilter: true },
          { align: 'left', sortable: true, groupable: true, text: 'Cidade', value: 'endereco.cidade.nome', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: true, text: 'Estado', value: 'endereco.estado.sigla', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'CEP', value: 'endereco.cep', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Latitude', value: 'latitude', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Longitude', value: 'longitude', width: 'auto' },
        ],
      }
    },
    computed: {
      ...mapState('fazendas', ['loading', 'fazendas']),
      ...mapGetters(['hasPermission']),
    },
    async created () {
      if (!this.$store.hasModule('fazendas')) { this.$store.registerModule('fazendas', FazendasStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('fazendas', [BOOTSTRAP, DELETE_CAD_FAZENDA, INATIVAR_CAD_FAZENDA, ATIVAR_CAD_FAZENDA, ADD_CARD_CADASTRO, INATIVAR_FAZENDA]),
      handleFilterInput (item) {
        item.checked = item.value || (item.value && item.checked)
      },
      mostraForm (id) {
        this.cadForm = {
          produtor_id: null,
          cod_produtor_fornecedor: null,
          cod_produtor_cliente: null,
          fazenda_id: id,
        }
      },
      editarFazenda ({ id }) {
        this.$router.push({
          path: `/${this.$user.get().role}/fazendas/alterar`,
          query: { id },
        })
      },
    },
  }
</script>
